<template>
  <div>
    <b-row>
      <b-col md="4"></b-col>
      <b-col md="2">
        <div class="d-flex justify-content-end col-10 text-end">
          <b-form-group>
            PLATFORM
            <b-form-select
              v-model="selected"
              :options="platform_options"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col md="2">
        <div class="d-flex justify-content-start col-10 text-start">
          <b-form-group>
            CATEGORY
            <b-form-select
              v-model="selected"
              :options="category_options"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="pb-1">
      <b-col md="9"></b-col>
      <b-col md="3">
        <div class="d-flex justify-content-end col-12 text-end">
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Search your Dapp"
              @input="searchMe(filter)"
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-card v-if="tableData" no-body class="card-company-table">
      <b-progress
        v-if="loading"
        :max="max"
        height="3px"
        :striped="true"
        :animated="true"
      >
        <b-progress-bar :value="value" variant="primary"> </b-progress-bar>
      </b-progress>
      <b-table
        id="table-crypto"
        hover
        :items="tableData"
        responsive
        :fields="fields"
        class="mb-0"
        :filter="selected"
        @row-clicked="detailView"
      >
        <!-- company -->
        <template #cell(no)="data">
          <span class="pl-1">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #cell(name)="data">
          <div class="pl-5 pb-1">
            <b-badge v-if="data.item.is_today_promoted == 1" variant="success"
              >Promoted</b-badge
            >
          </div>

          <div class="d-flex align-items-center">
            <b-avatar rounded size="45" variant="light-company">
              <b-img-lazy
                center
                fluid
                :src="data.item.logo_link"
                alt="avatar img"
                class="image-size"
            /></b-avatar>
            <div>
              <div class="font-weight-bolder pl-1">
                {{
                  !is_mobilesize
                    ? data.item.name
                    : data.item.name.slice(0, 8) + ".."
                }}
              </div>
              <div class="font-small-2 text-muted pl-1" v-if="!is_mobilesize">
                {{ data.item.symbol }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(Platform)="data">
          <div class="pl-5 pb-1">
            <b-badge v-if="data.item.Platform == 'ethereum'" variant="success"
              >ethereum</b-badge
            >
            <b-badge v-else>eos</b-badge>
          </div>
        </template>
        <template #cell(Users)="data">
          <div class="pl-5 pb-1">
            <span class="text-success">{{ data.item.usersU }} </span>
          </div>
          <div class="pl-5 pb-1">
            <span class="text-warning">{{ data.item.usersD }} </span>
          </div>
        </template>
        <template #cell(Volume)="data">
          <div class="pl-5 pb-1">
            <span class="text-success">{{ data.item.volume_EOS }} </span>
          </div>
          <div class="pl-5 pb-1">
            <span class="text-danger">{{ data.item.volume_USD }} </span>
          </div>
          <div class="pl-5 pb-1">
            <span class="text-warning">{{ data.item.volume_prentage }} </span>
          </div>
        </template>
        <template #cell(Dev_activity)="data">
          <div class="pl-5 pb-1">
            <span>{{ data.item.dev_activity }} </span>
          </div>
        </template>
      </b-table>
      <div class="text-center pb-1" v-if="!is_show_pagination > per_page">
        <b-button
          v-if="!loading"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="flat-secondary"
          @click="changePagination"
          class="font-weight-bolder"
        >
          See More
        </b-button>
        <b-spinner
          v-else
          label="Loading..."
          class="mr-2 mx-1"
          style="width: 13px; height: 13px"
        ></b-spinner>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import Ripple from "vue-ripple-directive";
// import SearchCoin from "./SearchCoin"
import {
  BCard,
  BTable,
  BAvatar,
  BImgLazy,
  BButton,
  BSpinner,
  BProgress,
  BProgressBar,
  BFormInput,
  BCol,
  BRow,
  BInputGroup,
  BBadge,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import numeral from "numeral";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImgLazy,
    BButton,
    BSpinner,
    BProgress,
    BProgressBar,
    BFormInput,
    BCol,
    BRow,
    BInputGroup,
    BBadge,
    BFormGroup,
    BFormSelect,
    // SearchCoin,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      selected: null,
      platform_options: [
        { value: null, text: "All" },
        { value: "eos", text: "eos" },
        { value: "ethereum", text: "ethereum" },
      ],
      category_options: [
        { value: null, text: "All" },
        { value: "games", text: "games" },
        { value: "gambling", text: "gambling" },
        { value: "finance", text: "finance" },
        { value: "media", text: "media" },
      ],
      tableData: [
        {
          id: "12332",
          logo_link: "https://dogejumbo.net/img/favicon.png",
          name: "DogeJumbo",
          symbol: "DOGEJ",
          Platform: "ethereum",
          Category: "games",
          usersU: "37,334",
          usersD: "-0.24%",
          volume_EOS: "0",
          volume_USD: "0",
          volume_prentage: "0",
          dev_activity: "34",
        },
        {
          id: "12332",
          logo_link:
            "https://chisaidoge.com/assets/img/listing/ChisaiDoge_200.png",
          name: "Chisai ",
          symbol: "Chisai ",
          Platform: "ethereum",
          Category: "gambling",
          usersU: "37,334",
          usersD: "-0.24%",
          volume_EOS: "0",
          volume_USD: "0",
          volume_prentage: "0",
          dev_activity: "34",
        },
        {
          id: "12332",
          logo_link:
            "https://images.hive.blog/DQmTdR9J6rwYf2vLPYp26G2ZnwMY3aZqkP5eQSd9Hu4Rrkh/SCR.png",
          name: "DogeJumbo",
          symbol: "DOGEJ",
          Platform: "eos",
          Category: "finance",
          usersU: "37,334",
          usersD: "-0.24%",
          volume_EOS: "0",
          volume_USD: "0",
          volume_prentage: "0",
          dev_activity: "34",
        },
        {
          id: "12332",
          logo_link:
            "https://pbs.twimg.com/profile_images/1392120113436844034/uCvm7Is1_400x400.jpg",
          name: "DogeJumbo",
          symbol: "DOGEJ",
          Platform: "ethereum",
          Category: "games",
          usersU: "37,334",
          usersD: "-0.24%",
          volume_EOS: "0",
          volume_USD: "0",
          volume_prentage: "0",
          dev_activity: "34",
        },
        {
          id: "12332",
          logo_link:
            "https://pbs.twimg.com/profile_images/1407254011833630722/OPoUaXIR_400x400.jpg",
          name: "DogeJumbo",
          symbol: "DOGEJ",
          Platform: "ethereum",
          Category: "media",
          usersU: "37,334",
          usersD: "-0.24%",
          volume_EOS: "0",
          volume_USD: "0",
          volume_prentage: "0",
          dev_activity: "34",
        },
      ],
      per_page: 20,
      is_show_pagination: false,
      transProps: {
        // Transition name
        name: "flip-list",
      },
      numeral,
      dayjs,
      relativeTime,
      sortBy: "vote_count",
      sortDesc: true,
      fields: [
        { key: "no", label: "NO" },
        { key: "name", label: "" },
        { key: "Platform", label: "Platform" },
        { key: "Category", label: "Category" },
        { key: "Users", label: "Users (24h) " },
        { key: "Volume", label: "Volume (7d) " },
        { key: "Dev_activity", label: "Dev activity (30d)" },
        { key: "User_activity", label: "User activity (30d)  " },
      ],
      fields_audited: [
        { key: "no", label: "NO" },
        { key: "name", label: "" },
        { key: "actual_market_cap", label: "MARKET CAP" },
        { key: "release_date", label: "RELEASED" },
        { key: "risk_level", label: "RISK LEVEL" },
        { key: "report", label: "Audit" },
        { key: "vote_count", label: "VOTES" },
      ],
      fields_mobile: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "risk_level", label: "RISK LEVEL" },
        // { key: "release_date", label: "RELEASED" },
        // { key: "actual_price", label: "PRICE" },
        { key: "vote_count", label: "VOTES" },
      ],
      selectId: null,
      value: 0,
      max: 100,
      windowHeight: window.innerWidth,
      filter: null,
    };
  },
  watch: {},
  created() {
    this.dayjs.extend(relativeTime);
  },
  computed: {
    loading() {
      return this.$store.state.loaders.loading;
    },
  },
  methods: {
    searchMe(val) {
      const data = {
        perPage: this.per_page,
        query: val,
      };
      setTimeout(() => {
        this.$store.dispatch("SEARCH_CRYPTO_DATA", data);
      }, 1000);
    },
    changePagination() {},
    startTimer() {
      let vm = this;
      let timer = setInterval(function () {
        vm.value += 20;
        if (vm.value >= 100) clearInterval(timer);
      }, 100);
    },
    castVote(coin) {
      this.selectId = coin.id;
      const data = {
        coinID: coin.id,
        perPage: this.per_page,
      };
      this.$store.dispatch("CAST_VOTE", data);
    },
    isVoted(isVoted) {
      return isVoted ? "success" : "outline-success";
    },
    detailView(data) {
      const name = data.name.replace(/[^A-Z0-9]/gi, "-");
      this.$router.push({
        name: "details",
        params: { id: data.id, name: name },
      });
    },
  },
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return null;
      }

      return dayjs(date).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
table#table-crypto .flip-list-move {
  transition: transform 1s;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0rem;
}
.button-class {
  margin: 0;
  width: 75px;
}
.desktop-button {
  margin: 0;
  width: 150;
}
.row-background {
  background-color: #f1f1f1;
}
@media (max-width: 1024px) {
  .image-size {
    max-width: 60% !important;
  }
}
</style>
